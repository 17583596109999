import React from 'react'
import { FluidObject } from 'gatsby-image'
import { graphql } from 'gatsby'

import { AppPageProps } from '../../types'
import { BlockList } from '../../components/BlockList'
import { breakpoints } from '../../styles/variables'
import { Button } from '../../components/Button'
import { ButtonList } from '../../components/ButtonList'
import { Gallery } from '../../components/Gallery'
import { Layout } from '../../components/Layout'
import { LoadingContext } from '../../contexts/LoadingContext'
import { ProductBlock } from '../../components/ProductBlock'
import { ProductImage } from '../../components/ProductImage'
import { ProductItem } from '../../components/ProductItem'
import { ProductRelatedList } from '../../components/ProductRelatedList'
import { SectionList } from '../../components/SectionList'
import { useIsTablet } from '../../hooks/useIsTablet'
import { WhiskyPageEnQuery } from '../../../types/graphql-types'

export const query = graphql`
  query WhiskyPageEn {
    maltLight: file(
      relativePath: { eq: "images/products-osuzu-malt@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    maltDark: file(
      relativePath: { eq: "images/products-osuzu-malt@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    maltNewBorn1Light: file(
      relativePath: { eq: "images/products-osuzu-malt-new-born-1@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    maltNewBorn1Dark: file(
      relativePath: { eq: "images/products-osuzu-malt-new-born-1@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    maltNewBorn2Light: file(
      relativePath: { eq: "images/products-osuzu-malt-new-born-2@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    maltNewBorn2Dark: file(
      relativePath: { eq: "images/products-osuzu-malt-new-born-2@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritsLight: file(
      relativePath: { eq: "images/products-spirits@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritsDark: file(
      relativePath: { eq: "images/products-spirits@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shochuLight: file(
      relativePath: { eq: "images/products-shochu@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shochuDark: file(relativePath: { eq: "images/products-shochu@dark.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

interface WhiskyPageProps extends AppPageProps {
  data: WhiskyPageEnQuery
}

const WhiskyPage: React.FC<WhiskyPageProps> = ({
  pathContext: { langKey },
  data: {
    maltLight,
    maltDark,
    maltNewBorn1Light,
    maltNewBorn1Dark,
    maltNewBorn2Light,
    maltNewBorn2Dark,
    spiritsLight,
    spiritsDark,
    shochuLight,
    shochuDark,
  },
}) => {
  const isTablet = useIsTablet()

  return (
    <Layout
      langKey={langKey}
      pageTitle="Whisky"
      titleLabel="Whisky"
      headerMode="product"
    >
      <SectionList>
        <BlockList>
          <ProductBlock
            image={
              <LoadingContext.Provider value="eager">
                <ProductImage
                  fluidLight={maltLight?.childImageSharp?.fluid as FluidObject}
                  fluidDark={maltDark?.childImageSharp?.fluid as FluidObject}
                  sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
                />
              </LoadingContext.Provider>
            }
            label={isTablet ? 'Osuzu Malt\nNew Make' : 'Osuzu Malt New Make'}
          >
            <p>
              Price: ¥2,750 (including tax)
              <br />
              Vol.: 200ml
              <br />
              Alc.: 59%
              <br />
            </p>
            <Button
              href="https://osuzuyama.co.jp/store/products/detail/38"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONLINE STORE
            </Button>
          </ProductBlock>
          <ProductBlock
            image={
              <LoadingContext.Provider value="eager">
                <Gallery>
                  <ProductImage
                    fluidLight={
                      maltNewBorn1Light?.childImageSharp?.fluid as FluidObject
                    }
                    fluidDark={
                      maltNewBorn1Dark?.childImageSharp?.fluid as FluidObject
                    }
                    sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
                  />
                  <ProductImage
                    fluidLight={
                      maltNewBorn2Light?.childImageSharp?.fluid as FluidObject
                    }
                    fluidDark={
                      maltNewBorn2Dark?.childImageSharp?.fluid as FluidObject
                    }
                    sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
                  />
                </Gallery>
              </LoadingContext.Provider>
            }
            label={isTablet ? 'Osuzu Malt\nNew Born' : 'Osuzu Malt New Born'}
          >
            <p>
              OSUZU MALT NEW BORN 200ml:
              <br />
              Price: ¥3,300 (including tax)
              <br />
              Vol.: 200ml
              <br />
              Alc.: 59%
              <br />
            </p>
            <p>
              OSUZU MALT NEW BORN 700ml:
              <br />
              Price: ¥11,550 (including tax)
              <br />
              Vol.: 700ml
              <br />
              Alc.: 59%
              <br />
            </p>
            <ButtonList>
              <Button
                href="https://osuzuyama.co.jp/store/products/detail/49"
                target="_blank"
                rel="noopener noreferrer"
              >
                ONLINE STORE (200ml)
              </Button>
              <Button
                href="https://osuzuyama.co.jp/store/products/detail/48"
                target="_blank"
                rel="noopener noreferrer"
              >
                ONLINE STORE (700ml)
              </Button>
            </ButtonList>
          </ProductBlock>
        </BlockList>
        <ProductRelatedList>
          <ProductItem
            to="/products/spirits/"
            fluidLight={spiritsLight?.childImageSharp?.fluid as FluidObject}
            fluidDark={spiritsDark?.childImageSharp?.fluid as FluidObject}
            label="Spirits"
            imageSizes={`(min-width: ${breakpoints.tablet}px) 20vw, 40vw`}
          />
          <ProductItem
            to="/products/shochu/"
            fluidLight={shochuLight?.childImageSharp?.fluid as FluidObject}
            fluidDark={shochuDark?.childImageSharp?.fluid as FluidObject}
            label="Shochu"
            imageSizes={`(min-width: ${breakpoints.tablet}px) 20vw, 40vw`}
          />
        </ProductRelatedList>
      </SectionList>
    </Layout>
  )
}

export default WhiskyPage
